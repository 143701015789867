import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../styles/global.css'
import { useIntl } from "gatsby-plugin-intl"
import Layout from '../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook,
        faLinkedin,
    } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Seo from '../components/seo';

export default function StaffPage ({data}) {
 
    const intl = useIntl()
    const locale = intl.locale !== "en" ? `/${intl.locale}` : ""

    return (          
        <Layout 
        isHome={false}>
            <div className='container'> 
                <section className="post-feed">
                {data.allContentfulStaff.edges.map((edge) => (
                    <div key={edge.id}>
                        <Link to={`${locale}/staff/${edge.node.slug}`} className="post-card">
                            <header className="post-card-header">
                                    <GatsbyImage
                                    className="post-card-image" 
                                    image= {getImage(edge.node.profilePic)}
                                    />
                                <h1 className="post-card-title">{intl.formatMessage({ id: `${edge.node.name}` })}</h1>
                                <h2 className="post-card-excerpt">{intl.formatMessage({ id: `${edge.node.position}` })}</h2>
                            </header>
                        </Link>  
                        <footer className="post-card-footer">
                            <div className="post-card-avatar">
                                <a 
                                href={`mailto:${edge.node.email}`}
                                target='_blank'
                                rel='noreferrer'
                                className="author-profile-image">
                                    <FontAwesomeIcon 
                                    icon={faEnvelope} 
                                    alt="Email"
                                    size='lg'/>
                                </a>
                                <a 
                                href={`${edge.node.linkedinLink}`}
                                target='_blank'
                                rel='noreferrer'
                                className="author-profile-image">
                                    <FontAwesomeIcon 
                                    icon={faLinkedin} 
                                    alt="LinkedIn"
                                    size='lg'/>
                                </a>
                                <a
                                href={`${edge.node.facebookLink}`}
                                target='_blank'
                                rel='noreferrer'
                                className="author-profile-image">
                                    <FontAwesomeIcon  
                                    icon={faFacebook} 
                                    alt="Facebook"
                                    size='lg'/>
                                </a>             
                            </div>
                        </footer>
                    </div>
                ))}
                </section>
            </div>
        </Layout>
    )
}

export const Head = ({ pageContext }) => (
    <Seo
    title={`${pageContext.intl.messages["Staff"]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
    description={pageContext.intl.messages["At his best, man is the noblest of all animals; separated from law and justice he is the worst."]}
    article={false}>
    </Seo>
)

export const staffQuery = graphql`
query staffQuery {
    allContentfulStaff(sort: {fields: order, order: ASC}) {
      edges {
        node {
          id
          facebookLink
          linkedinLink
          email
          name
          slug
          order
          position
          profilePic {
            gatsbyImageData
          }
        }
      }
    }
  }  
`;